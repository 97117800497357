"use client"
import { ApolloProvider } from '@apollo/client';
import client from '@/constants/apolloClient';

const AppProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}

export default AppProvider;